<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;">

				<el-form :inline="true" :model="queryForm">
					<el-form-item label="名称" min-width="120">
						<el-input v-model="queryForm.title" size="small" placeholder="请输入名称"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" size="small" @click="search">搜索</el-button>

						<el-button type="primary" size="small" @click="add">添加</el-button>

					</el-form-item>
				</el-form>

			</div>
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>积分商城</el-breadcrumb-item>
					<el-breadcrumb-item>商品管理</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<el-table :data="DataList" stripe border size="small">
				<el-table-column prop="id" label="编号" width="80">
				</el-table-column>
				<el-table-column prop="cover" label="封面" width="80">
					<template slot-scope="scope">
						<div style="width: 50px; height: 50px; overflow: hidden;">
							<el-image  fit="fit" :src="scope.row.cover" style="width: 50px; vertical-align: middle;"
							:preview-src-list="[scope.row.cover]" ></el-image>
						</div>

					</template>
				</el-table-column>
				<el-table-column prop="goods_name" label="名称" min-width="200">
				</el-table-column>
				<el-table-column prop="id" label="规格" width="200">
					<template slot-scope="scope">
						{{ scope.row.spec_title }}-{{ scope.row.size_title }}
					</template>
				</el-table-column>
				<el-table-column prop="sold" label="已售出" width="80">

				</el-table-column>
				<el-table-column prop="is_hot" label="热销" width="80">
					<template slot-scope="scope">
						{{ scope.row.is_hot ? '是' : '否' }}
					</template>
				</el-table-column>
				<el-table-column prop="id" label="状态" width="200">
					<template slot-scope="scope">
						<span style="color: #2C3E50;" v-if="scope.row.status == 1">正常</span>
						<span style="color: #FF4949;" v-if="scope.row.status != 1">下架</span>

					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="150">
					<template slot-scope="scope">
						<el-button type="text" v-if="false"
							@click="updateState(scope.row)">{{ scope.row.status == 1 ? '下架' : '上架' }}</el-button>
						<el-button type="text" @click="update(scope.row)">编辑</el-button>
						<el-button type="text" v-if="scope.row.id != 1" @click="remove(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div
			style="position: absolute; bottom: 0; height: 40px; width: 100%; text-align: right; background-color: #fff; z-index: 20;">
			<el-pagination @current-change="paginate" :current-page="Page.current_page" :page-size="Page.per_page"
				:total="Page.count" layout="total, prev, pager, next">
			</el-pagination>
		</div>

	</div>
</template>

<script>

export default {
	components: {

	},
	data() {
		return {
			queryForm: {
				title: ""
			},
			DataList: [],
			Page: {
				current_page: 1,
				next_page: 1,
				pre_page: 10,
				total_page: 1,
				count: 0
			},
			product_catalog: []
		}
	},

	mounted() {
		this.query(1);

	},
	methods: {
		paginate(val) {
			this.query(val)
		},
		search() {
			this.query(1)
		},
		query(page) {
			this.$http.post('/api/good_getList', {
				page: page,

				name: this.queryForm.title ? this.queryForm.title : "",

			}).then(res => {
				this.DataList = res.data.data
				this.Page = res.data.page
			});
		},
		update(item) {

			//sessionStorage.setItem("product", JSON.stringify(item))

			this.$router.push("/proedit/" + item.id)
		},
		updateState(e) {

			this.$http.post('/product/online-offline', {
				id: e.id,
				status: (e.status == 1 ? 0 : 1)
			}).then(res => {
				this.$message({
					type: 'success',
					message: '修改成功'
				});
				this.query(this.page.current_page);
			});

		},
		add() {
			this.$router.push("/proedit/0")
		},
		remove(id) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/good_deleteItem", {
					id: id
				}).then(res => {
					this.$message({
						type: 'success',
						message: '删除成功'
					});
					this.query(1);
				}, e => {

					this.$message({
						message: e.data.msg,
						type: 'error'
					});
				})

			}).catch((e) => {

			});
		}

	}
}
</script>
<style scoped>
.product-status {
	padding-bottom: 10px;
	border-bottom: 5px solid #e8e8e8;
}
</style>
